import { buildMediaQueries, getImage, ImageQuery } from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox,
  zIndex
} from '@rategravity/own-up-component-library';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { metadata } from '../../../data/metadata/images';
import VerticalDotStrip2SVG from '../../../images/about/dot-strip-vertical.svg';
import { colors } from '../../../modules/colors';
import { SectionCount } from '../../count';
import { ResponsivePrimaryText, ResponsiveSectionHeader } from '../../typography';

const SectionStyle = createOwnUpStyle({
  display: 'flex',
  position: 'relative',
  margin: '0 30px',
  variants: {
    mediumAndDown: {
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: 50
    }
  }
});

const Section = createOwnUpComponent('section', SectionStyle);

const ContentWrapperStyle = createOwnUpStyle({
  padding: 64,
  marginRight: 40,
  boxSizing: 'border-box',
  variants: {
    mediumAndDown: {
      padding: 30,
      margin: 0,
      width: '100%'
    }
  }
});

const ContentWrapper = createOwnUpComponent(OwnUpBox, ContentWrapperStyle);

const FirstStyle = createOwnUpStyle({
  width: '50%',
  backgroundColor: colors.MINT,
  variants: {
    mediumAndDown: {
      width: '100%'
    }
  }
});

const First = createOwnUpComponent(OwnUpBox, FirstStyle);

const BackgroundImage = styled('div')`
  background-position: center;
  background-image: url(${VerticalDotStrip2SVG});
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: ${zIndex.StickyTableHeader};
  ${buildMediaQueries('mediumAndDown', {
    display: 'none'
  })}
  ${buildMediaQueries('large', {
    display: 'flex'
  })}
`;

const SecondStyle = createOwnUpStyle({
  width: '50%',
  variants: {
    mediumAndDown: {
      width: '100%'
    }
  }
});

const Second = createOwnUpComponent(OwnUpBox, SecondStyle);

export const imgStyle = {
  height: '100%',
  margin: 'auto',
  position: 'relative' as const
};

export const AtComputerImage = ({ imageData }: { imageData: ImageQuery }) =>
  getImage(imageData, 'parent-and-baby-at-computer.jpg', metadata, imgStyle);

export const Section2 = ({ children }: PropsWithChildren<{}>) => (
  <Section aria-label="section 2">
    <BackgroundImage />
    <First>
      <ContentWrapper>
        <SectionCount>{2}</SectionCount>
        <ResponsiveSectionHeader>
          We re-wrote
          <br /> the rules.
        </ResponsiveSectionHeader>
        <ResponsivePrimaryText>
          Unlike mortgage lenders, our interests are aligned with yours, so we can be objective
          about assessing your options. In the end, you save some money, we make some money, and the
          lender makes a loan. Everybody wins.
        </ResponsivePrimaryText>
      </ContentWrapper>
    </First>
    <Second>{children}</Second>
  </Section>
);
